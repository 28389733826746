<template>
  <div class="page">
    <el-form size="small" :model="orderForm" class="agentEdit">
      <el-card class="page-content">
        <h3>基本信息</h3>
        <el-row>
          <el-col :span='12'>
            <el-form-item label="订单状态">
              <span>{{ detail.check_status_msg }}</span>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label="所属BA">
              <el-select v-if="canEdit" v-model="orderForm.ba_id" placeholder="请选择所属BA" @change="updateShop"
                         filterable remote :remote-method="getBAList" :loading="searchLoading">
                <el-option
                    v-for="item in BAList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
              <span v-else>{{ detail.ba_name }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='12'>
            <el-form-item label="小票编号">
              <el-col :span="8" v-if="canEdit">
                <el-input clearable v-model="orderForm.receipt_number" placeholder="请输入小票编号"></el-input>
              </el-col>
              <span v-else>{{ detail.receipt_number }}</span>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label="购买日期">
              <el-date-picker
                  v-if="canEdit"
                  v-model="orderForm.buy_time"
                  type="datetime"
                  placeholder="选择日期时间">
              </el-date-picker>
              <span v-else>{{ detail.buy_time }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='12'>
            <el-form-item label="积分金额">
              <el-col :span="8" v-if="canEdit">
                <el-input clearable v-model="orderForm.real_cost" placeholder="请输入积分金额" disabled/>
              </el-col>
              <span v-else>{{ detail.real_cost }}</span>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label="参考金额">
              <span>{{ detail.reference_amount }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='12'>
            <el-form-item label="店铺编号">
              <span>{{ detail.shop_number }}</span>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label="店铺名称">
              <span>{{ detail.shop_name }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <!--        <el-row>-->
        <!--          <el-col :span='12'>-->
        <!--            <el-form-item label="参考金额">-->
        <!--              <span>{{detail.reference_amount}}</span>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--          <el-col :span='12'>-->
        <!--            <el-form-item label="实发金额">-->
        <!--              <span>{{detail.actual_amount}}</span>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <!--        <el-row>-->
        <!--          <el-col :span='12'>-->
        <!--            <el-form-item label="奖励积分">-->
        <!--              <span>{{detail.award_point}}</span>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <h3>小票录入截图</h3>
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <ali-uploader
                  :limitSize="limitSize"
                  :autoUpload="false"
                  :uploadFolder="false"
                  :showUploadBtn="false"
                  :accept="imgAccept"
                  :showPreview="true"
                  :previewImg="orderForm.pic_origin_url"
                  uploadRef="receipt"
                  ref="receipt"
                  @getDir="getDir"
                  @beforeUpload="beforeUpload"
                  @handleChange="handleChange"
                  @handleFailed="handleFailed"
                  :hidedel="!canEdit"
                  @getUploadUrl="url => (orderForm.pic_url = url)"
              ></ali-uploader>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <p style="color: #bbb; margin: 12px 0;">
              仅限jpg、png格式
            </p>
          </el-col>
        </el-row>
        <el-row v-if="orderForm.pic_urls.length < 5 && canEdit">
          <el-col :span="24">
            <el-form-item>
              <ali-uploader
                  :limitSize="limitSize"
                  :autoUpload="false"
                  :uploadFolder="false"
                  :showUploadBtn="false"
                  :isPicList="true"
                  :accept="imgAccept"
                  :showPreview="false"
                  uploadRef="picReceipt"
                  ref="picReceipt"
                  @getDir="getDir"
                  @beforeUpload="beforeUpload"
                  @handleChange="handleChange"
                  @handleFailed="handleFailed"
                  @getUploadUrl="(url, path) => this.handlePicChange(url, path)"
                  :hidedel="!canEdit"
              ></ali-uploader>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="orderForm.pic_urls.length">
            <ul class="picList">
              <li v-for="(item, index) in orderForm.pic_urls" :key="index" class="picItem">
                <img :src="item.picUrl" alt=""/>
                <div class="actions">
                  <i class="el-icon-delete del" v-if="canEdit" @click="deletePic(index)"></i>
                  <i class="el-icon-full-screen preview" @click="viewPic(index)"></i>
                </div>
              </li>
            </ul>
          </el-col>
          <el-col :span="24">
            <p style="color: #bbb; margin: 12px 0;">
              仅限jpg、png格式
            </p>
          </el-col>
        </el-row>
        <h3>商品信息
          <el-button v-if="canEdit == true" size="small" type="primary" @click="handleAdd()" style="margin-left: 15px;">
            添加
          </el-button>
        </h3>
        <v-table
            :data="goodsList"
            :columns="productOptions.columns"
            :isBackPage="true"
            :showPagination="false"
            @buttonClick="handleButtonClick"
        >
        </v-table>
        <div style="margin-top: 25px;" v-if="canEdit">
          <el-button size="small" type="primary" @click="quitEdit('quit')">取消</el-button>
          <el-button size="small" type="success" @click="handleSave">保 存</el-button>
        </div>
      </el-card>
      <el-card class="page-content">
        <h3>销售人员</h3>
        <el-row>
          <el-col :span='12'>
            <el-form-item label="BA姓名：">
              <span>{{ detail.ba_name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label="BA工号：">
              <span>{{ detail.ba_number }}</span>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label="BA手机号：">
              <span>{{ detail.ba_mobile }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="page-content">
        <h3>系统审核</h3>
        <p>{{ detail.system_check_note ? detail.system_check_note : '——' }}</p>
      </el-card>
      <el-card class="page-content">
        <h3>人工审核意见</h3>
        <p>{{ detail.employee_remark ? detail.employee_remark : '——' }}</p>
      </el-card>
      <el-card class="page-content">
        <h3>日志</h3>
        <p v-for="(item, index) in detail.log_list" :key="index">{{ item }}</p>
      </el-card>
    </el-form>
    <el-dialog
        :visible.sync="goodsDialog"
        @close="goodsDialog = false"
        width="30%"
        center>
      <div class="userMessageParent">
        <el-form size="small" :model="goods" class="agentEdit" label-width="100px">
          <el-form-item label="商品">
            <el-select
                v-model="goods.id"
                clearable
                filterable
                remote
                reserve-keyword
                placeholder="请输入商品名称"
                :remote-method="querySearch"
                :loading="loading">
              <el-option
                  v-for="item in goodsOptions"
                  :key="item.id"
                  :label="item.goods_name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品数量">
            <el-input clearable v-model="goods.num" placeholder="请输入商品数量"/>
          </el-form-item>
          <el-form-item label="是否退款">
            <el-select v-model="goods.status">
              <el-option
                  v-for="item in refundOptions"
                  :key="item.code"
                  :label="item.value"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addGoods()">确 定</el-button>
        <el-button @click="goodsDialog = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="imageUrl" alt=""/>
    </el-dialog>
  </div>
</template>

<script>
import {BAApi} from '@/api'
import AliUploader from '@/components/tools/aliUploader'
import simpleOssUpload from '@/mixins/simpleOssUploadMixin'
import vTable from '@/components/tools/vtable'
import {formatDate} from '@/libs/utils'

export default {
  mixins: [simpleOssUpload],
  components: {
    AliUploader,
    vTable
  },
  data() {
    return {
      title: '添加',
      edit: false,
      canEdit: false,
      receipt_id: '',
      orderForm: {
        receipt_from: '1',
        ba_id: '',
        receipt_number: '',
        pic_url: '',
        pic_origin_url: '',
        buy_time: '',
        real_cost: '',
        pic_urls: [],
        pic_list_url: ''
      },
      detail: {
        receipt_number: '',
        ba_name: '',
        shop_number: '',
        shop_name: '',
        create_time: '',
        reference_money: '',
        actual_money: '',
        check_status_zh: '',
        ba_mobile:''
      },
      BAList: [],
      limitSize: '5',
      productOptions: {
        columns: [
          {
            label: '商品编码',
            key: 'goods_number'
          },
          {
            label: '商品名称',
            key: 'goods_name'
          },
          {
            label: '数量',
            key: 'num'
          },
          {
            label: '是否退款',
            key: 'status_msg'
          },
          {
            label: '单价',
            key: 'market_price'
          },
          {
            label: '操作',
            type: 'action',
            width: 180,
            selectButton: true,
            buttonInfos: [{
              name: 'edit',
              label: '编辑',
            },
              {
                name: 'del',
                label: '删除',
                color: 'danger'
              }]
          }
        ]
      },
      refundOptions: [
        {
          code: 1,
          value: '是'
        },
        {
          code: 2,
          value: '否'
        },
      ],
      goodsList: [],
      goodsOptions: [],
      goods: {
        status: '',
        market_price: 0,
      },
      goodsRowIndex: '',
      goodsDialog: false,
      BA: {},
      loading: false,
      dialogVisible: false,
      imageUrl: '',
      searchLoading: false
    }
  },
  methods: {
    querySearch(query) {
      if (!query) {
        return
      }
      this.loading = true
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.selectGoods(query);
      }, 500);
    },
    quitEdit() {
      window.history.go(-1)
    },
    // 点击事件
    handleButtonClick(data) {
      this.goodsRowIndex = data.rowIndex
      if (data.button === 'edit') {
        this.goodsDialog = true
        this.goods = {...Object.assign(this.goods, data.data)}
        this.goods.status = Number(data.data.status) || 2
        this.goodsOptions = [data.data]
      } else {
        this.goodsList.splice(data.rowIndex, 1);
        let real_cost = 0
        this.goodsList.forEach((item, index) =>
            real_cost += item.market_price * item.num
        )
        this.orderForm.real_cost = real_cost
      }
    },
    handleAdd() {
      this.goods = Object.assign(this.goods, {
        status: 2
      })
      this.goodsRowIndex = ''
      this.goodsDialog = true
      this.goodsOptions = []
    },
    addGoods() {
      this.goodsDialog = false
      let currentGoods = this.goodsOptions.filter(item => item.id === this.goods.id)[0]
      let {goods_name, goods_number, market_price} = currentGoods
      this.goods = Object.assign(this.goods, {goods_name, goods_number, market_price})
      this.goods.status_msg = this.goods.status === 1 ? '是' : '否'
      if (this.goodsRowIndex !== '') {
        this.goodsList.splice(this.goodsRowIndex, 1, {...this.goods});
      } else {
        this.goods.btnList = ['edit', 'del']
        this.goodsList.splice(this.goodsList.length, 1, {...this.goods});
      }
      if (this.goodsList.length > 0) {
        let real_cost = 0;
        this.goodsList.forEach((good, index) =>
            real_cost += good.market_price * good.num
        )
        this.orderForm.real_cost = real_cost
      }
    },
    // BA
    async getBAList(query, type) {
      this.searchLoading = true
      let params = {check_status: 2}
      if (query && type) {
        params.id = query
      } else {
        params.mobile = query
      }
      try {
        let data = (await BAApi.getBaList(params)).data
        if (data.code === 0) {
          this.searchLoading = false
          this.BAList = data.data.apply_list
        } else {
          this.searchLoading = false
          this.$error(data)
        }
      } catch (err) {
        this.searchLoading = false
        console.error(err)
      }
    },
    updateShop() {
      if (!this.orderForm.ba_id) {
        return this.$message.error('请选择所属BA')
      }
      this.BA = this.BAList.filter(item => item.id === this.orderForm.ba_id)[0]
      let {shop_number, shop_name} = this.BA
      this.detail.shop_number = shop_number
      this.detail.shop_name = shop_name
    },
    async selectGoods(query) {
      let params = {goods_name: query}
      try {
        let data = (await BAApi.getReceiptGoodsList(params)).data
        if (data.code === 0) {
          this.loading = false
          this.goodsOptions = data.goods_list
        } else {
          this.$error(data)
        }
      } catch (err) {
        this.loading = false
        console.error(err)
      }
    },
    // 保存
    isEmptyForm(obj) {
      let res = Object.keys(obj).filter(item => {
        if (Array.isArray(item)) {
          return item.length === 0
        } else {
          return !item
        }
      })
      return res.length > 0
    },
    handleSave() {
      if (this.isEmptyForm(this.orderForm)) {
        return this.$message.error('缺少必要信息！')
      }
      if (this.goodsList.length === 0) {
        return this.$message.error('未添加商品')
      }
      let params = {...this.orderForm}
      params.pic_url = this.orderForm.pic_url || this.orderForm.pic_origin_url
      params.shop_id = this.BA.shop_id
      params.goods_info = this.goodsList
      params.pic_urls = this.orderForm.pic_urls.map(item => item.picPath)
      if (this.canEdit) {
        params.receipt_id = this.receipt_id
      }
      // 编辑
      this.$confirm('确定保存更改？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updateReceipt(params)
      }).catch(() => {
      })
    },
    async updateReceipt(params) {
      let loading = this.$loading()
      try {
        let data = (await BAApi.updateReceipt(params)).data
        if (data.code === 0) {
          this.$message.success('保存成功！')
          loading.close()
          this.getOrderDetail()
          this.canEdit = false
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        loading.close()
        console.log(error)
      }
    },
    handlePicChange(picPath, picUrl) {
      const obj = {
        picUrl,
        picPath
      }
      this.orderForm.pic_urls.push(obj)
    },
    deletePic(index) {
      const arr = [...this.orderForm.pic_urls]
      arr.splice(index, 1)
      this.orderForm.pic_urls = arr
    },
    viewPic(index) {
      const item = this.orderForm.pic_urls[index]
      this.dialogVisible = true
      this.imageUrl = item.picUrl
    },
    // 获取小票详情
    async getOrderDetail() {
      let loading = this.$loading()
      try {
        let data = (await BAApi.getOrderDetail(this.receipt_id)).data
        if (data.code === 0) {
          const {ba_id, receipt_number, pic_url: pic_origin_url, buy_time, real_cost, ba_mobile} = data.receipt_info
          this.canEdit && this.getBAList(ba_id, 'ba_id')
          let pic_urls = []
          if (data.receipt_info.pic_urls) {
            pic_urls = [...data.receipt_info.pic_urls].map(item => {
              return {
                picPath: item,
                picUrl: item
              }
            })
          }
          this.orderForm = Object.assign(this.orderForm, {
            ba_id,
            receipt_number,
            pic_origin_url,
            buy_time,
            real_cost,
            pic_urls
          })
          this.detail = Object.assign(this.detail, data.receipt_info)
          this.BA = {
            shop_id: this.detail.shop_id
          }
          this.goodsList = data.receipt_info.goods.map(item => {
            if (!this.canEdit) {
              item.btnList = []
            } else {
              item.btnList = ['del', 'edit']
            }
            return item
          })
          this.detail.buy_time = formatDate(data.receipt_info.buy_time, 'YYYY-MM-DD HH:mm:ss').dateString
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.thisImageFolder = '0'
      this.receipt_id = this.$route.query.id
      this.canEdit = Number(this.$route.query.canEdit) === 1
      this.title = this.canEdit === 'false' ? '订单详情' : '订单编辑'
      this.getOrderDetail()
    }
  }
}
</script>
<style lang="less" scoped>
.page-content {
  position: relative;
  margin-bottom: 15px;

  h3 {
    margin-bottom: 15px;
  }

  .page-header {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .tag {
    color: #F56C6C;
  }

  .picList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .picItem {
      height: 260px;
      width: 180px;
      position: relative;
      background-color: #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
      margin-bottom: 25px;
      border-radius: 6px;

      img {
        max-width: 180px;
        max-height: 260px;
        height: auto;
        width: auto;
      }

      .actions {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        height: 45px;
        background-color: rgba(0, 0, 0, .65);
        align-items: center;
        justify-content: space-around;
        border-radius: 0 0 6px 6px;

        i {
          font-size: 22px;
        }

        .del {
          color: #F56C6C;
        }

        .preview {
          color: #409EFF;
        }
      }
    }
  }
}
</style>
